<template>
  <v-card-text
    v-if="showCardContent"
    class="pa-2 pt-0 service-card-content"
  >
    <v-progress-linear
      :active="refreshIntervalOverlay"
      absolute
      top
      indeterminate
      color="green"
      height="5"
    />
    <vector-layer
      :vector-layers="vectorLayers"
      :service-card-id="serviceCardId"
      :animation-limit-range-array="animationLimitRangeArray"
      :animation-limit-range-array-policy="animationLimitRangeArrayPolicy"
      :animation-step-date-type="animation_step_date_type"
      :animation-step="animation_step"
      :animation-limit-date-type="animation_limit_date_type"
      :animation-limit-future="animation_limit_future"
      :animation-limit-past="animation_limit_past"
      :min-step="minStep"
      :max-step="maxStep"
      :is-animation="isAnimation"
      :is-table="isTable"
      :is-graph="isGraph"
      :service-name="serviceName"
      :layer-to-select-vector="map.layerToSelectVector[serviceCardId] || {}"
      :policy-limits="{ type: policy_date_type, past: policy_start_date, future: policy_end_date }"
      :refresh-interval-step-type="refresh_interval_step_type"
      :refresh-interval-step="refresh_interval_step"
      :check-policy-limits="checkPolicyLimits()"
      :is-meteogram="isMeteogram"
      :meteogram-enabled="meteogramEnabled"
      :meteogram-obj="meteogramObj"
      @toggle-meteogram-btn="toggleMeteogram($event)"
      @reset-min-max-step="changeMinMaxStepLimit()"
      @set-refresh-interval-overlay="setRefreshIntervalOverlay($event)"
      @check-specific-default-service="$emit('check-specific-default-service')"
    />
    <raster-layer
      :service-selected="serviceSelected"
      :raster-layers="rasterLayers"
      :service-card-id="serviceCardId"
      :animation-limit-range-array="animationLimitRangeArray"
      :animation-limit-range-array-policy="animationLimitRangeArrayPolicy"
      :animation-step-date-type="animation_step_date_type"
      :animation-step="animation_step"
      :animation-limit-date-type="animation_limit_date_type"
      :animation-limit-future="animation_limit_future"
      :animation-limit-past="animation_limit_past"
      :min-step="minStep"
      :max-step="maxStep"
      :is-animation="isAnimation"
      :is-table="isTable"
      :is-graph="isGraph"
      :service-name="serviceName"
      :layer-to-select-raster="map.layerToSelectRaster[serviceCardId] || {}"
      :policy-limits="{ type: policy_date_type, past: policy_start_date, future: policy_end_date }"
      :refresh-interval-step-type="refresh_interval_step_type"
      :refresh-interval-step="refresh_interval_step"
      :check-policy-limits="checkPolicyLimits()"
      :is-meteogram="isMeteogram"
      :meteogram-enabled="meteogramEnabled"
      :meteogram-obj="meteogramObj"
      @toggle-meteogram-btn="toggleMeteogram($event)"
      @closeRasterService="closeRasterService($event)"
      @reset-min-max-step="changeMinMaxStepLimit()"
      @set-refresh-interval-overlay="setRefreshIntervalOverlay($event)"
      @check-specific-default-service="$emit('check-specific-default-service')"
    />
    <v-alert
      v-model="meteogramEnabled"
      dense
      text
      border="left"
      color="green"
      type="info"
      class="mt-3 service-card-content"
      icon="mdi-gesture-tap"
      transition="scale-transition"
    >
      {{ $t('Meteogram.activeMsg') }}
    </v-alert>
  </v-card-text>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { METEOGRAM_LAYERS } from '@/constants.js';

export default {
  components: {
    VectorLayer: () => import('@/components/layout/VectorLayer'),
    RasterLayer: () => import('@/components/layout/RasterLayer')
  },
  props: {
    allWorkspaces: {
      type: Array,
      default: () => []
    },
    serviceSelected: {
      type: Array,
      default: () => []
    },
    serviceCardId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      animation_limit_date_type: '',
      animation_limit_future: '',
      animation_limit_past: '',
      animation_step_date_type: '',
      animation_step: '',
      refresh_interval_step_type: '',
      refresh_interval_step: '',
      policy_date_type: '',
      policy_start_date: '',
      policy_end_date: '',
      serviceName: '',
      minStep: null,
      maxStep: null,
      isAnimation: false,
      isTable: false,
      isGraph: false,
      meteogramEnabled: false,
      refreshIntervalOverlay: false,
      animationLimitRangeArray: [],
      animationLimitRangeArrayPolicy: [],
      globalLayers: [],
      rasterLayers: [],
      vectorLayers: []
    };
  },
  computed: {
    ...mapState(['policy', 'wsServices', 'map', 'table_chart', 'app_user']),
    activeLayers() {
      return this.map.OLmap.getLayers().getArray().filter(
        (layer) => layer.values_.service_id == this.serviceCardId).length > 0;
    },
    showCardContent() {
      const condition = this.serviceSelected.some(service => service.split(', ')[1] === this.serviceCardId);

      if (condition) {
        this.changeLayers();
      }

      return condition;
    },
    isMeteogram() {
      return this.allWorkspaces.some(w => {
        if (w.service_id === this.serviceCardId) {
          return w.ws_policy.some(l => l.is_meteogram);
        }

        return false;
      });
    },
    meteogramObj() {
      const meteogramObj = { ...this.allWorkspaces.find(w => w.service_id === this.serviceCardId) };

      if (meteogramObj.ws_policy) {
        meteogramObj.ws_policy = meteogramObj.ws_policy.filter(layer => METEOGRAM_LAYERS.includes(layer.layer_name));
      }

      return meteogramObj;
    }
  },
  watch: {
    $route() {}
  },
  methods: {
    ...mapActions([]),
    changeLayers() {
      this.rasterLayers = [];
      this.vectorLayers = [];

      this.allWorkspaces.forEach(ws => {
        ws.ws_policy.forEach(layer => {
          if (ws.ws_id == layer.ws_id && ws.ws_group == this.$route.params.id && ws.service_id === this.serviceCardId) {
            this.animation_limit_date_type = ws.animation_limit_date_type;
            this.animation_limit_future = ws.animation_limit_future;
            this.animation_limit_past = ws.animation_limit_past;
            this.animation_step_date_type = ws.animation_step_date_type;
            this.animation_step = ws.animation_step;
            this.refresh_interval_step_type = ws.refresh_interval_step_type;
            this.refresh_interval_step = ws.refresh_interval_step;
            this.archive_start_date = ws.archive_start_date;
            this.policy_date_type = ws.ws_policy[0].date_type;
            this.policy_start_date = ws.ws_policy[0].start_date;
            this.policy_end_date = ws.ws_policy[0].end_date;
            this.isAnimation = ws.ws_policy[0].is_animation;
            this.isTable = ws.ws_policy[0].is_table;
            this.isGraph = ws.ws_policy[0].is_graph;
            this.serviceName = ws.service_name;

            if (layer.layer_type === 'RASTER') {
              this.rasterLayers.push({ ...layer, ws_name: ws.ws_name, service_name: ws.service_name });
            } else {
              this.vectorLayers.push({ ...layer, ws_name: ws.ws_name });
            }
          }
        });
      });
      this.rasterLayers.sort((a, b) => (this.$t(`${a.ws_name}:${a.layer_name}_serviceID:${this.serviceCardId}.label`).localeCompare(this.$t(`${b.ws_name}:${b.layer_name}_serviceID:${this.serviceCardId}.label`), 'en', { numeric: true })));
      this.vectorLayers.sort((a, b) => (this.$t(`${a.ws_name}:${a.layer_name}_serviceID:${this.serviceCardId}.label`).localeCompare(this.$t(`${b.ws_name}:${b.layer_name}_serviceID:${this.serviceCardId}.label`), 'en', { numeric: true })));
      this.changeAnimationLimitRangeArray();
      this.changeMinMaxStepLimit();
      this.defaultServiceWSselectedLayers();
      this.selectIfSingleLayer();
    },
    changeAnimationLimitRangeArray() {
      this.animationLimitRangeArray = [];
      this.animationLimitRangeArrayPolicy = [];

      switch (this.policy_date_type) {
        case 'dynamic_months':
          this.getDynamicStep('dynamic_months', true);
          break;
        case 'dynamic_weeks':
          this.getDynamicStep('dynamic_weeks', true);
          break;
        case 'dynamic_hours':
          this.getDynamicStep('dynamic_hours', true);
          break;
        default:
          this.getDynamicStep('dynamic_days', true);
          break;
      }

      switch (this.animation_limit_date_type) {
        case 'dynamic_months':
          this.getDynamicStep('dynamic_months', false);
          break;
        case 'dynamic_minutes':
          this.getDynamicStep('dynamic_minutes', false);
          break;
        case 'dynamic_hours':
          this.getDynamicStep('dynamic_hours', false);
          break;
        default:
          this.getDynamicStep('dynamic_days', false);
          break;
      }
    },
    getDynamicStep(date_type, isPolicy) {
      let startDate = new Date();
      let endDate = new Date();

      let animation_limit_past = 0;
      let animation_limit_future = 0;
      let arrayForPush = [];

      if (isPolicy) {
        arrayForPush = this.animationLimitRangeArrayPolicy;

        if (this.policy_start_date.includes('-')) {
          startDate = new Date(this.policy_start_date);
        } else {
          if (this.policy_start_date !== '') {
            animation_limit_past = parseInt(this.policy_start_date);
          }
        }

        if (this.policy_end_date.includes('-')) {
          endDate = new Date(this.policy_end_date);
        } else {
          if (this.policy_end_date !== '') {
            animation_limit_future = parseInt(this.policy_end_date);
          }
        }
      } else {
        arrayForPush = this.animationLimitRangeArray;

        if (this.archive_start_date !== '') {
          startDate = new Date(this.archive_start_date);
        }

        if (this.animation_limit_past !== '') {
          animation_limit_past = parseInt(this.animation_limit_past);
        }

        if (this.animation_limit_future !== '') {
          animation_limit_future = parseInt(this.animation_limit_future);
        }
      }

      switch (date_type) {
        case 'dynamic_months':
          startDate.setMonth(startDate.getMonth() - animation_limit_past);
          endDate.setMonth(endDate.getMonth() + animation_limit_future);

          while (startDate <= endDate) {
            arrayForPush.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
          }

          break;
        case 'dynamic_weeks':
          startDate.setDate(startDate.getDate() - animation_limit_past * 7);
          endDate.setDate(endDate.getDate() + animation_limit_future * 7);

          while (startDate <= endDate) {
            arrayForPush.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
          }

          break;
        case 'dynamic_days':
          startDate.setDate(startDate.getDate() - animation_limit_past);
          endDate.setDate(endDate.getDate() + animation_limit_future);

          while (startDate <= endDate) {
            arrayForPush.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
          }

          break;
        case 'dynamic_hours':
          startDate.setHours(startDate.getHours() - animation_limit_past);
          endDate.setHours(endDate.getHours() + animation_limit_future);

          while (startDate <= endDate) {
            arrayForPush.push(new Date(startDate));
            startDate.setHours(startDate.getHours() + 1);
          }

          break;
        default:
          startDate.setMinutes(startDate.getMinutes() - animation_limit_past);
          endDate.setMinutes(endDate.getMinutes() + animation_limit_future);

          while (startDate <= endDate) {
            arrayForPush.push(new Date(startDate));
            startDate.setMinutes(startDate.getMinutes() + 1);
          }

          break;
      }
    },
    changeMinMaxStepLimit() {
      this.minStep = null;
      this.maxStep = null;

      const minStepLimit = this.animationLimitRangeArray[0];
      const maxStepLimit = this.animationLimitRangeArray.at(-1);
      const minStepPolicy = this.animationLimitRangeArrayPolicy[0];
      const maxStepPolicy = this.animationLimitRangeArrayPolicy.at(-1);

      if ((minStepPolicy >= minStepLimit) && (maxStepPolicy <= maxStepLimit)) {
        this.minStep = minStepPolicy;
        this.maxStep = maxStepPolicy;
      } else {
        this.minStep = minStepLimit;
        this.maxStep = maxStepLimit;
      }
      // log.info(
      //   "animation_limit_date_type", this.animation_limit_date_type,
      //   "animation_limit_future", this.animation_limit_future,
      //   "animation_limit_past", this.animation_limit_past,
      //   "animation_step_date_type", this.animation_step_date_type,
      //   "animation_step", this.animation_step,
      //   "refresh_interval_step_type", this.refresh_interval_step_type,
      //   "refresh_interval_step", this.refresh_interval_step,
      //   "archive_start_date", this.archive_start_date,
      //   "policy_date_type", this.policy_date_type,
      //   "policy_start_date", this.policy_start_date,
      //   "policy_end_date", this.policy_end_date,
      //   "MIN STEP", this.minStep,
      //   "MAX STEP", this.maxStep
      // );
    },
    defaultServiceWSselectedLayers() {
      const fromRoute = this.$route.params.id.replace('-', '');
      const isDefaultService = this.app_user.defaultService.GLOBAL?.service_type === 'WS';
      const isSpecificDefaultService = this.app_user.defaultService[fromRoute]?.service_type && this.map.specificDefaultService[fromRoute];

      if (isDefaultService || isSpecificDefaultService) {
        const SCOPE = isDefaultService ? 'GLOBAL' : fromRoute;
        this.app_user.defaultService[SCOPE].service_details.forEach(item => {
          if (item.layer_name) {
            const toSelect = item.layer_name.split(':');

            if (item.layer_type === 'VECTOR') {
              const objVector = this.vectorLayers.find(layer => layer.layer_name === toSelect[1] && layer.ws_name === toSelect[0] && layer.service_id === item.service_id);

              if (objVector !== undefined) {
                this.map.layerToSelectVector[item.service_id] = objVector;
              }
            }

            if (item.layer_type === 'RASTER') {
              const objRaster = this.rasterLayers.find(layer => layer.layer_name === toSelect[1] && layer.ws_name === toSelect[0] && layer.service_id === item.service_id);

              if (objRaster !== undefined) {
                this.map.layerToSelectRaster[item.service_id] = objRaster;
              }
            }
          }
        });
      }
    },
    selectIfSingleLayer() {
      const isInDefaultService = this.app_user.defaultService.GLOBAL?.service_type === 'WS'
        ? this.app_user.defaultService.GLOBAL?.service_details.some(item => item.service_id === this.serviceCardId)
        : false;

      const fromRoute = this.$route.params.id.replace('-', '');
      const isInSpecificDefaultService = this.app_user.defaultService[fromRoute]?.service_type && this.map.specificDefaultService[fromRoute]
        ? this.app_user.defaultService[fromRoute].service_details.some(item => item.service_id === this.serviceCardId)
        : false;

      if (!isInDefaultService && !isInSpecificDefaultService) {
        const noIgnoreVectorLayers = this.vectorLayers.filter(item => !item.layer_name.includes('ignore'));

        if (noIgnoreVectorLayers.length === 1) {
          this.map.layerToSelectVector[this.serviceCardId] = noIgnoreVectorLayers[0];
        }

        if (this.rasterLayers.length === 1) {
          this.map.layerToSelectRaster[this.serviceCardId] = this.rasterLayers[0];
        }
      }
    },
    closeRasterService(service_id) {
      this.$emit('closeRasterService', service_id);
    },
    setRefreshIntervalOverlay(bool) {
      this.refreshIntervalOverlay = bool;
      this.$emit('disable-close-service', bool);
    },
    checkPolicyLimits() {
      const type = this.policy_date_type;
      const past = this.policy_start_date;
      const future = this.policy_end_date;
      const stepType = this.animation_step_date_type;
      const step = this.animation_step;

      if (type === 'static') {
        return {
          pastRangeTime: new Date(past),
          futureRangeTime: new Date(future)
        };
      }

      const nowDateMinus = new Date(Date.now());
      const nowDatePlus = new Date(Date.now());
      const checkDailyData = stepType === 'dynamic_days' || (stepType === 'dynamic_hours' && step >= 24);

      const currentDateMinus = checkDailyData || stepType === 'dynamic_months' ? new Date(nowDateMinus.setHours(0, 0, 0, 0)) : nowDateMinus;
      let currentDatePlus = nowDatePlus;

      if (checkDailyData || stepType === 'dynamic_months') {
        currentDatePlus = checkDailyData
          ? new Date(nowDatePlus.setHours(24, 0, 0, 0))
          : new Date(nowDatePlus.setMonth(nowDatePlus.getMonth() + parseInt(step)));
      }

      if (type === 'dynamic_hours') {
        return {
          pastRangeTime: currentDateMinus.setHours(currentDateMinus.getHours() - parseInt(past)),
          futureRangeTime: currentDatePlus.setHours(currentDatePlus.getHours() + parseInt(future))
        };
      }

      if (type === 'dynamic_days') {
        return {
          pastRangeTime: currentDateMinus.setDate(currentDateMinus.getDate() - parseInt(past)),
          futureRangeTime: currentDatePlus.setDate(currentDatePlus.getDate() + parseInt(future))
        };
      }

      if (type === 'dynamic_weeks') {
        return {
          pastRangeTime: currentDateMinus.setDate(currentDateMinus.getDate() - (parseInt(past) * 7)),
          futureRangeTime: currentDatePlus.setDate(currentDatePlus.getDate() + (parseInt(future) * 7))
        };
      }

      if (type === 'dynamic_months') {
        return {
          pastRangeTime: currentDateMinus.setMonth(currentDateMinus.getMonth() - parseInt(past)),
          futureRangeTime: currentDatePlus.setMonth(currentDatePlus.getMonth() + parseInt(future))
        };
      }
    },
    toggleMeteogram(value) {
      this.meteogramEnabled = value;
      this.map.meteogramActive = this.meteogramEnabled;
    }
  }
};
</script>
